import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL } from "../../../services/baseURL";

export async function geturl() {
  try {
    const response = await axios({
      url: `${baseURL}/geturl`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
