import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { geturl } from "../services/url.service";

export function ModalExencion({ showModalExencion, setShowModalExencion }) {
  const [link, setLink] = useState("");

  const {
    register,
    reset,
    handleSubmit,
    watch,
  } = useForm({ defaultValues: { isValid: "" } });

  const rerouteSharepoint = () => {
    if (link) {
      window.open(link);
    } else {
      console.log("No link found");
    }
  };

  const fetchLink = async () => {
    try {
      const response = await geturl(); // Ajusta la consulta según sea necesario
      console.log(response);
      if (response) {
        const link = extractLink(response[0].post_content);
        setLink(link);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const extractLink = (response) => {
    // Asumiendo que la respuesta es una cadena serializada
    const regex = /s:13:"default_value";s:\d+:"(https?:\/\/[^"]+)";/;
    const match = response.match(regex);
    console.log(match);
    return match ? match[1] : "";
  };

  function onSubmit(e) {
    reset();
  }

  useEffect(() => {
    onSubmit();
    fetchLink();
  }, []);

  return (
    <>
      {showModalExencion ? (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-full px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3 sm:flex justify-center"></div>

              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <div>
                  <>
                    <section className="max-w-screen-sm mx-auto">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="">
                          <div className="">
                            <h3 className="pb-8 text-black text-center font-bold text-xl text-title">
                              Exención de responsabilidad
                            </h3>
                            <p className="text-black font-normal text-md text-parrafo">
                              1) Los recursos que se comparten se realizaron en el
                              marco del Programa de Negocios Competitivos del
                              GRI con el objetivo de guiar a las organizaciones
                              participantes en su proceso de reporte. Los
                              ejemplos que se incluyen en algunos de los
                              recursos se usan de manera académica. Recuerde que
                              para mayor comprensión de los requerimientos de
                              los Contenidos de los Estándares GRI es su
                              responsabilidad revisar la información contenida
                              en cada uno de los Estándares GRI como por ejemplo
                              en la sección de Introducción, así como en las
                              Orientaciones que se suministran. <br/><br/>
                              2) El uso, copia o
                              duplicación del material por fuera del Programa de
                              Negocios Competitivos está prohibido por parte del
                              GRI. Si desea usarla por fuera del programa,
                              deberá solicitarlo y obtener una aprobación por
                              escrito del GRI. <br/><br/>
                               Ni la Junta Directiva de GRI, ni
                              el GSSB, ni Stichting Global Reporting Initiative
                              (GRI) pueden responsabilizarse de las
                              consecuencias o los daños resultantes directa o
                              indirectamente del uso de los recursos por parte
                              de terceros.
                            </p>
                          </div>

                          <div className="flex  flex-end items-center pt-2 gap-2">
                            <input
                              type="checkbox"
                              value="1"
                              name="isValid"
                              className="rounded cursor-pointer"
                              {...register("isValid")}
                              required
                            />
                            <label className="text-black font-normal text-sm text-parrafo">Aceptar excepción de responsabilidad</label>
                          </div>
                        </div>
                        <div className="flex justify-center flex-wrap pt-8 gap-2">
                          <input
                            onClick={(e) => {
                              rerouteSharepoint(e);
                              setShowModalExencion(false);
                            }}
                            type="submit"
                            className="cursor-pointer boxshadow disabled:opacity-50  btn-generarReporte"
                            disabled={!watch("isValid")}
                          />
                          <button
                            onClick={() => setShowModalExencion(false)}
                            type="button"
                            className="text-zinc-500 font-normal text-sm text-parrafo btn-cancel-reporte ring-slate-400 border-solid border-2 ring-slate-400 boxshadow cursor-pointer"
                          >
                            Cancelar
                          </button>
                        </div>
                      </form>
                    </section>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
